import { actions } from './icons/actions'
import { add } from './icons/add'
import { arrowBack } from './icons/arrow-back'
import { burger } from './icons/burger'
import { checked } from './icons/checked'
import { closeDialog } from './icons/close'
import { deleteIcon } from './icons/delete'
import { download } from './icons/download'
import { edit } from './icons/edit'
import { emblem } from './icons/emblem'
import { eye } from './icons/eye'
import { fb } from './icons/facebook'
import { file } from './icons/file'
import { herald } from './icons/herald'
import { hi } from './icons/hi'
import { info } from './icons/info'
import { instagram } from './icons/instagram'
import { linkArrow } from './icons/link-arrow'
import { logoWhite } from './icons/logo-white'
import { mail } from './icons/mail'
import { map } from './icons/map'
import { more } from './icons/more'
import { navArrow } from './icons/nav-arrow'
import { paginationArrow } from './icons/pagination-arrow'
import { penLogo } from './icons/pen-logo'
import { phone } from './icons/phone'
import { plus } from './icons/plus'
import { polygon } from './icons/polygon'
import { question } from './icons/question'
import { save } from './icons/save'
import { telegram } from './icons/telegram'
import { twitter } from './icons/twitter'
import { SvgIcon } from './svg-icons.enum'

import { search } from 'src/app/shared/svg-icons/icons/search'
import { support } from 'src/app/shared/svg-icons/icons/support'

export const icons = {
  [SvgIcon.SEARCH]: search,
  [SvgIcon.SUPPORT]: support,
  [SvgIcon.POLYGON]: polygon,
  [SvgIcon.ACTIONS]: actions,
  [SvgIcon.ARROW_BACK]: arrowBack,
  [SvgIcon.ADD]: add,
  [SvgIcon.CLOSE]: closeDialog,
  [SvgIcon.EYE]: eye,
  [SvgIcon.CHECKED]: checked,
  [SvgIcon.DELETE]: deleteIcon,
  [SvgIcon.MORE]: more,
  [SvgIcon.DOWNLOAD]: download,
  [SvgIcon.FILE]: file,
  [SvgIcon.EDIT]: edit,
  [SvgIcon.PAGINATION_ARROW]: paginationArrow,
  [SvgIcon.BURGER]: burger,
  [SvgIcon.SAVE]: save,
  [SvgIcon.EMBLEM]: emblem,
  [SvgIcon.LOGO_WHITE]: logoWhite,
  [SvgIcon.HI]: hi,
  [SvgIcon.HERALD]: herald,
  [SvgIcon.PEN_LOGO]: penLogo,
  [SvgIcon.NAV_ARROW]: navArrow,
  [SvgIcon.FB]: fb,
  [SvgIcon.TELEGRAM]: telegram,
  [SvgIcon.TWITTER]: twitter,
  [SvgIcon.INSTAGRAM]: instagram,
  [SvgIcon.INFO]: info,
  [SvgIcon.PLUS]: plus,
  [SvgIcon.MAP]: map,
  [SvgIcon.Phone]: phone,
  [SvgIcon.Mail]: mail,
  [SvgIcon.Question]: question,
  [SvgIcon.LinkArrow]: linkArrow,
}
